import React from 'react'
import Layout from "../components/App/YftelLayout"
import Navbar from "../components/App/YftelNavbar2"
import PageBanner from '../components/Common/YftelPageBanner'
import Footer from "../components/App/YftelFooter"
import AboutUsContent from '../components/Nosotros/AboutUsContent'
import OurHistory from '../components/Nosotros/OurHistory'
import WhyChooseUs from '../components/Nosotros/WhyChooseUs'
import HowItWork from '../components/Nosotros/HowItWork'
import TeamMembers from '../components/Nosotros/TeamMembers'
import Testimonials from '../components/Nosotros/Testimonials'
import Partner from '../components/Nosotros/Partner'
 
const AboutUs = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Nosotros" 
                homePageText="Inicio" 
                homePageUrl="/" 
                activePageText="Nosotros" 
            />
            <AboutUsContent />
            <Footer />
        </Layout>
    );
}

export default AboutUs;
