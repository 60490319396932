import React from 'react'
import { Link } from 'gatsby'
import aboutImage from '../../assets/images/about/about-img5.png'
import starIcon from '../../assets/images/star-icon.png'
import icon4 from '../../assets/images/icons/icon4.png'
import icon5 from '../../assets/images/icons/icon5.png'
import icon6 from '../../assets/images/icons/icon6.png'
import icon7 from '../../assets/images/icons/icon7.png'
import shape1 from '../../assets/images/shape/circle-shape1.png'
 
const AboutUsContent = () => {
    return (
        <section className="about-area ptb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                            <img src={aboutImage} alt="banner" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="banner" /> 
                                    Nosotros
                                </span>
                                <h2>Innovación a su servicio</h2>
                                <p>En Yftel sabemos que eres un operador de internet que quiere crecer rentablemente.  Para lograrlo, tienes que reducir la morosidad y aumentar la satisfacción de tus clientes. El problema es la tasa de abandono y morosidad podría colocar en riesgo por la viabilidad del negocio.  Creemos que todo operador exitoso debe optimizar las tasas de churn y morosidad.</p>
                                
                                <p>Yftel ha desarrollado una plataforma de experiencia de usuario, control de acceso y portal cautivo wifi  que se integran fácilmente con los sistemas propios de los operadores de internet con redes FTTH.  Nuestra plataforma permite a nuestros clientes monitorear 24/7 en forma automatizada las variables que inciden en la satisfacción de tus clientes y morosidad del servicio</p>
                                <p>YFTEL formado por Ingenieros con experiencia en Informática y telecomunicaciones,provenientes de los principales Operadores del País</p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>

            <div className="container">
                <div className="about-inner-area">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Misión</h3>
                                <p>Nuestra misión es ayudar a crecer a nuestros clientes y que obtengan una ventaja competitiva que conlleve a la satisfacción de sus usuarios, entendiendo sus necesidades, brindando soluciones innovadoras y ofreciendo un servicio de excelencia.</p>
                                
                            </div>
                        </div>
    
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Visión</h3>
                                <p>Empresa consolidada en Latinoamérica en la prestación de servicio de licenciamiento de software carrier class al sector de telecomunicaciones, llegando a posicionarse en el mercado objetivo en cada país en el que tengamos presencia.</p>
                                
                            </div>
                        </div>
    
                        <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                            <div className="about-text">
                                <h3>Valores</h3>
                                <p>Nuestra vocación de servicio nos lleva a:.</p>
                                <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Excelencia en el Servicio.</li>
                                    <li><i className="flaticon-tick"></i> Innovacion Constante</li>
                                    <li><i className="flaticon-tick"></i> Excelencia Operativa</li>
                                    <li><i className="flaticon-tick"></i> Relacion a largo plazo con el cliente</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>
        </section>
    )
}

export default AboutUsContent;
